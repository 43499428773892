@font-face {
    font-family: 'DinPro';
    font-style: normal;
    font-weight: 400;
    src: url('./d_din_pro_2/d_din_pro/otf/D-DIN-PRO-400-Regular.otf') format('opentype');
}
@font-face {
  font-family: 'DinPro';
  font-style: medium;
  font-weight: 500;
  src: url('./d_din_pro_2/d_din_pro/otf/D-DIN-PRO-500-Medium.otf') format('opentype');
}
@font-face {
  font-family: 'DinPro';
  font-style: bold;
  font-weight: 600;
  src: url('./d_din_pro_2/d_din_pro/otf/D-DIN-PRO-600-SemiBold.otf') format('opentype');
}
@font-face {
  font-family: 'DinPro';
  font-style: bold;
  font-weight: 700;
  src: url('./d_din_pro_2/d_din_pro/otf/D-DIN-PRO-700-Bold.otf') format('opentype');
}